const baseURL = process.env.NEXT_PUBLIC_API_ENDPOINT;

export const generateOptions = (method, body) => ({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    method,
    body: body ? JSON.stringify(body) : undefined,
    credentials: 'include',
});

const fetcher = async (path, method = 'GET', body) => {
    const res = await fetch(baseURL + path, generateOptions(method, body));

    if (!res.ok) {
        const error = new Error(
            `An error occurred while fetching the data => ${
                res.status
            } ${await res.text()}`
        );
        error.status = res.status;
        throw error;
    }

    if (res.status === 204) {
        return;
    }

    return res.json();
};

export default fetcher;
