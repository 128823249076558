import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

import useDeleteAnalysis from '../services/api/analyses/useDeleteAnalysis';

const AnalysesSelectorContext = createContext();

const AnalysesSelectorProvider = ({ children }) => {
    const [selectedAnalyses, setSelectedAnalyses] = useState([]);
    const [selectedAnalysesState, setSelectedAnalysesState] = useState([]);
    const router = useRouter();

    const toggleSelectedAnalysis = (analysisId, analysisState) => {
        const analyses = [...selectedAnalyses];
        const analysesState = [...selectedAnalysesState];
        const index = analyses.indexOf(analysisId);
        if (index === -1) {
            analyses.push(analysisId);
            analysesState.push(analysisState);
        } else {
            analyses.splice(index, 1);
            analysesState.splice(index, 1);
        }

        setSelectedAnalyses(analyses);
        setSelectedAnalysesState(analysesState);
    };

    const unselectAnalyses = () => {
        setSelectedAnalyses([]);
        setSelectedAnalysesState([]);
    };

    const deleteAnalyses = () => {
        for (let i = 0; i < selectedAnalyses.length; i++) {
            useDeleteAnalysis(selectedAnalyses[i]);
        }
        setSelectedAnalyses([]);
        setSelectedAnalysesState([]);
    };

    const isFailedAnalysisSelected = () => {
        for (let i = 0; i < selectedAnalyses.length; i++) {
            if (selectedAnalysesState[i] === 'failed') return true;
        }
        return false;
    };

    const goToCompareView = () => {
        router.push({
            pathname: `/analyses/${selectedAnalyses[0]}`,
            query: { compareWith: selectedAnalyses[1] },
        });
    };

    return (
        <AnalysesSelectorContext.Provider
            value={{
                selectedAnalyses,
                selectedAnalysesState,
                toggleSelectedAnalysis,
                unselectAnalyses,
                deleteAnalyses,
                isFailedAnalysisSelected,
                goToCompareView,
            }}
        >
            {children}
        </AnalysesSelectorContext.Provider>
    );
};

AnalysesSelectorProvider.propTypes = {
    children: PropTypes.element,
};

const useAnalysesSelector = () => useContext(AnalysesSelectorContext);

export { AnalysesSelectorProvider, useAnalysesSelector };
