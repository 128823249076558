import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { SWRConfig } from 'swr';

import { AnalysesSelectorProvider } from '../providers/AnalysesSelectorProvider';
import { AuthProvider } from '../providers/AuthProvider';
import fetcher from '../services/fetcher';
import '../styles/globals.css';
import theme from '../theme';

const baseUrl = process.env.PUBLIC_BASE_URL;

export default function MyApp(props) {
    const { Component, pageProps } = props;
    const router = useRouter();

    const [previousLocation, setPreviousLocation] = React.useState();

    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    const handle401Error = debounce(() => {
        if (pageProps.isPageProtected) {
            setPreviousLocation(router.asPath);
            router.push('/logout');
        }
    }, 500);

    const handle404Error = () => router.push('/404');

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <>
                    <Head>
                        {/* General tags */}
                        <title>
                            GreenFrame - Measure the Carbon Footprint of your Website
                        </title>

                        {/* OpenGraph tags */}
                        <meta property="og:site_name" content="GreenFrame Enterprise" />
                        <meta
                            key="og:title"
                            property="og:title"
                            content="GreenFrame Enterprise - Measure the Carbon Footprint of your Website"
                        />
                        <meta
                            key="og:image"
                            property="og:image"
                            content={`${baseUrl}/meta.png`}
                        />
                        <meta
                            key="og:image:alt"
                            property="og:image:alt"
                            content="GreenFrame Enterprise - Measure the Carbon Footprint of your Website"
                        />
                        <meta
                            key="og:image:width"
                            property="og:image:width"
                            content="772"
                        />
                        <meta
                            key="og:image:height"
                            property="og:image:height"
                            content="484"
                        />

                        {/* Twitter Card tags */}
                        <meta name="twitter:card" content="summary_large_image" />

                        {/* Favicon & Images */}
                        <link
                            rel="icon"
                            type="image/png"
                            sizes="32x32"
                            href="/favicon-32x32.png"
                        />
                        <link
                            rel="icon"
                            type="image/png"
                            sizes="16x16"
                            href="/favicon-16x16.png"
                        />
                        <link rel="manifest" href="/site.webmanifest" />
                        <link
                            rel="mask-icon"
                            href="/safari-pinned-tab.svg"
                            color="#5bbad5"
                        />
                        <link
                            rel="apple-touch-icon"
                            sizes="180x180"
                            href="/apple-touch-icon.png"
                        />
                        <meta
                            name="viewport"
                            content="initial-scale=1.0, width=device-width"
                        />
                        <meta name="msapplication-TileColor" content="#ffffff" />
                        <meta
                            name="msapplication-TileImage"
                            content="/mstile-150x150.png"
                        />
                        <meta name="theme-color" content="#ffffff" />
                    </Head>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />

                    <SWRConfig
                        value={{
                            fetcher,
                            shouldRetryOnError: false,
                            revalidateOnFocus: false,
                            onError: (error) => {
                                if (error.status === 401) {
                                    handle401Error();
                                } else if (error.status === 404) {
                                    handle404Error();
                                }
                            },
                        }}
                    >
                        <AuthProvider>
                            <AnalysesSelectorProvider>
                                <Component
                                    {...pageProps}
                                    previousLocation={previousLocation}
                                />
                            </AnalysesSelectorProvider>
                        </AuthProvider>
                    </SWRConfig>
                </>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};
