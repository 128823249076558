import { createTheme } from '@mui/material/styles';

export const percentileBarColors = [
    '#48d5c0',
    '#45bdb6',
    '#41a5ad',
    '#3b8ea3',
    '#337899',
    '#eebf5e',
    '#db9950',
    '#c77342',
    '#b34c35',
    '#9d1b28',
];

// https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
const computeTextColorFromBackgroundColor = (color) => {
    const hexcolor = color.slice(0, 1) === '#' ? color.slice(1) : color;

    const red = parseInt(hexcolor.substring(0, 2), 16);
    const green = parseInt(hexcolor.substring(2, 4), 16);
    const blue = parseInt(hexcolor.substring(4, 6), 16);

    return red * 0.299 + green * 0.587 + blue * 0.114 > 165 ? 'black' : 'white';
};

export const createPercentileColorBuilder = (percentiles) => (whTotal) => {
    if (!percentiles || percentiles.length === 0) return;
    const firstPercentile = percentiles[0];
    const lastPercentile = percentiles[percentiles.length - 1];
    const percentileIndex = percentiles.findIndex(({ max }) => whTotal <= max);
    const midPercentileOffset = 50 / percentiles.length; // to center le result in the percentile
    const percentageCurrentScore =
        whTotal < firstPercentile.min //  may occur if the current analysis is faster than all previously done analysis
            ? 5
            : whTotal > lastPercentile.max // may occur if the current analysis is slower than all previously done analysis
            ? 95
            : Math.round(
                  (percentileIndex * 100) / percentiles.length + midPercentileOffset
              );

    const colorIndex = Math.floor(percentageCurrentScore / 10);

    return {
        background: percentileBarColors[colorIndex],
        color: computeTextColorFromBackgroundColor(percentileBarColors[colorIndex]),
    };
};

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#48D5C0',
        },
        secondary: {
            main: '#2A628F',
        },
        tertiary: {
            main: '#FFE66D',
        },
        error: {
            main: '#9D1B28',
        },
        success: {
            main: '#48D5C0',
        },
        dark: {
            main: '#091620',
        },
        white: {
            main: '#FFFFFF',
        },
        red: {
            main: '#ff0000',
        },
        grey: {
            light: '#FAF6F6',
            main: '#ecf0f1',
            dark: '#C4D1D4',
        },
        background: {
            default: '#FAF6F6',
        },
        heatMap: {
            primary: ['#A7F08F', '#83E9A2', '#62E0B3', '#48D5C0'],
        },
        percentileBarMap: {
            main: percentileBarColors,
        },
    },
    typography: {
        h1: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
            fontWeight: 700,
            fontSize: '2.5rem',
        },
        h2: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
            fontWeight: 700,
            fontSize: '2rem',
        },
        h3: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
            fontWeight: 700,
            fontSize: '1.8rem',
        },
        h4: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
            fontWeight: 700,
            fontSize: '1.6rem',
        },
        h5: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
            fontWeight: 700,
            fontSize: '1.4rem',
        },
        h6: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
            fontWeight: 700,
            fontSize: '1.2rem',
        },
        subtitle1: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
            fontWeight: 700,
        },
        subtitle2: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
        },
        overline: {
            fontFamily: ['Barlow', 'BlinkMacSystemFont', 'Arial'].join(','),
        },
        button: {
            fontWeight: 700,
        },
        caption: {
            fontFamily: ['Barlow'],
            fontSize: '1rem',
        },
    },
});

export default theme;
