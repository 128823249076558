import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: (props) => (props.fullPage ? `calc(100vh - ${2 * 58}px)` : 'auto'),
    },
    '@keyframes spinner': {
        to: {
            transform: 'rotate(360deg)',
        },
    },
    spinner: {
        width: (props) => (props.isSmall ? '60px' : '80px'),
        height: (props) => (props.isSmall ? '60px' : '80px'),
        borderRadius: '50%',
        border: '6px solid transparent',
        borderTopColor: theme.palette.primary.main,
        borderBottomColor: theme.palette.secondary.main,
        animation: '$spinner .8s ease infinite',
    },
}));
const Loader = ({ fullPage, isSmall }) => {
    const classes = useStyles({ fullPage, isSmall });

    return (
        <div className={classes.root}>
            <div className={classes.spinner}></div>
        </div>
    );
};

Loader.propTypes = {
    fullPage: PropTypes.bool,
    isSmall: PropTypes.bool,
};

Loader.defaultProps = {
    fullPage: false,
    isSmall: false,
};

export default Loader;
