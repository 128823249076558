import PropTypes from 'prop-types';
import { useSWRConfig } from 'swr';

import Loader from '../components/common/Loader';
import useGetIdentityOrganization from '../services/api/organizations/useGetIdentityOrganization';
import useGetIdentity from '../services/api/users/useGetIdentity';

const { createContext, useContext } = require('react');

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const { mutate } = useSWRConfig();

    const { user, isError, isLoading } = useGetIdentity();
    const { organization } = useGetIdentityOrganization();

    const resetUser = () => {
        // Mutate data by resetting the value to null and disabling the automatic revalidation.
        // Useful for logout
        mutate('/me', null, false);
        mutate('/me/organizations', null, false);
    };

    const refetchUser = () => mutate('/me');
    const refetchOrganization = () => mutate('/me/organizations');

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: isError ? false : !!user,
                user,
                refetchUser,
                organization,
                refetchOrganization,
                resetUser,
            }}
        >
            {isLoading ? <Loader fullPage={true} /> : children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.element,
};

const useAuthProvider = () => useContext(AuthContext);

export { useAuthProvider, AuthProvider };
