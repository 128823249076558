import useSWR from 'swr';

const useGetIdentity = () => {
    const { data, error, isValidating } = useSWR(`/me/organizations`);

    return {
        organization: !error ? data : null,
        error: isValidating ? undefined : error,
        isLoading: !error && !data && isValidating,
        isValidating,
        isError: !!error && !data,
    };
};

export default useGetIdentity;
